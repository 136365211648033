import { motion } from "framer-motion";
import Image from "../assets/avatar.png";

const Photo = () => {
  return (
    <div className='w-full h-full relative'>
      <motion.div
        initial={{ opacity: 1 }}
        animate={{
          opacity: 1,
          transition: { delay: 1.6, duration: 0.4, ease: "easeIn" },
        }}
      >
        <motion.div
          initial={{ opacity: 1 }}
          animate={{
            opacity: 1,
            transition: { delay: 2, duration: 0.4, ease: "easeInOut" },
          }}
          className='w-[298px] h-[298px] xl:w-[498px] xl:h-[498px] mix-blend-lighten absolute max-sm:w-[200px] max-sm:h-[200px]'
        >
          <div className='w-full h-full rounded-full overflow-hidden'>
            <img
              src={Image}
              alt='Portfolio Image'
              priority
              quality={100}
              fill
              className='object-contain'
            />
          </div>
        </motion.div>

        <motion.svg
          className='w-[300px] xl:w-[506px] h-[300px] xl:h-[506px] max-sm:w-[200px] max-sm:h-[200px]'
          fill='transparent'
          viewBox='0 0 506 506'
          xmlns={"http://www.w3.org/2000/svg"}
        >
          <motion.circle
            cx='253'
            cy='253'
            r='250'
            stroke='#b936ee'
            strokeWidth='4'
            strokeLinecap={"round"}
            strokeLinejoin={"round"}
            initial={{ strokeDasharray: "24 10 0 0" }}
            animate={{
              strokeDasharray: ["15 120 25 25", "16 25 92 72", "4 250 22 22"],
              rotate: [120, 360],
              transition: {
                duration: 20,
                repeat: Infinity,
                repeatType: "reverse",
              },
            }}
          />
        </motion.svg>
      </motion.div>
    </div>
  );
};

export default Photo;
